function Hero() {
    return (
        <section id="hero" class="d-flex align-items-center">
            <div class="container">

                <div class="row">
                    <div class="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1" data-aos="fade-up" data-aos-delay="200">
                        <h1>Better Parking Solutions For Your Business</h1>
                        <h2>We are a team of talented software engineers improving the parking experience nationwide</h2>
                        <div class="d-flex justify-content-center justify-content-lg-start">
                            <a href="#about" class="btn-get-started scrollto">Learn More</a>
                            {/*<a href="https://www.youtube.com/watch?v=uBK1_bEGVqc" class="glightbox btn-watch-video"><i class="bi bi-play-circle"></i><span>Watch Video</span></a>*/}
                        </div>
                    </div>
                    <div class="col-lg-6 order-1 order-lg-2 hero-img" data-aos="zoom-in" data-aos-delay="200">
                        <img src="assets/img/hero-img.png" class="img-fluid animated" alt="" />
                    </div>
                </div>
                
            </div>
        </section>
    );
}

export default Hero;